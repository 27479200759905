import React from 'react';

import Head from './Head';
import StandardPage from './StandardPage';

import '../style/_common.scss';
import ShareRow from './ShareRow';
import CTASection from './CTASection';
import LearnMore from './LearnMore';

const BlogPost = props => {
  return (
    <StandardPage>
      <Head
        title={props.title}
        description={props.description}
        url={props.link}
        image="/img/banner.png"
      />

      <div className="col ac jc wrap" style={{ backgroundColor: 'white', padding: '100px 0px' }}>
        <div className="row as jc wide">
          <div className="wide readable col js ac blog" style={{ paddingTop: '150px' }}>
            <h1 className="title">{props.title}</h1>
            {props.children}
            <ShareRow label />
            <div style={{ height: '100px' }} />
            <CTASection />
            <div style={{ height: '100px' }} />
            <LearnMore {...props.learnMore || { home: true, faq: true, crisis: true }} />
          </div>
        </div>
      </div>
    </StandardPage>
  );
};

export default BlogPost;
