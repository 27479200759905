import React, { Component } from 'react';

import BlogPost from '../../components/BlogPost';
import { gasend } from '../../util';

export default class test extends Component {
  render() {
    return (
      <BlogPost
        title={'The Sad State of the Healthcare "Market"'}
        description={
          "The two main players — doctors and patients — have no clue how much anything costs. Instead, they've both hired a middleman to decide prices on their behalf, but these middlemen aren't on your side."
        }
        link={'/essays/the-healthcare-market/'}
        learnMore={{
          home: true,
          faq: true,
          crisis: true
        }}>
        <p>
          A typical hospital bills your insurance 10-20x more than a fair market price for any given
          medical procedure. This isn't surprising: the healthcare industry is a chaotic mess of
          middlemen and bureaucrats, and everyone takes a cut. When you cut out everyone except a
          doctor and a patient, you'd be amazed at how low prices can get.
        </p>
        <p>
          The healthcare industry is simple in theory: doctors provide medical services to patients
          in exchange for money. Brilliant! But a generation ago, patients stopped actually paying
          for these services themselves, instead relying on their insurance plans to cover{' '}
          <span style={{ fontStyle: 'italic' }}>everything</span> on their behalf. This is already
          extremely strange; in what other industry does a third party pay for everything you buy,
          in exchange for a flat monthly rate?
        </p>
        <p>
          Imagine what this would look like in the restaurant industry. You'd pay $2000 per month
          for "food insurance", and in exchange your food insurance company would pay for all your
          meals! Go out to any restaurant, provide your food insurance information, and order what
          you like! Of course, you'd save a ton of money if you just payed the menu prices, but
          since your employer (and Obamacare) mandated that you buy a comprehensive food insurance
          policy, you might as well use it!
        </p>
        <p>
          Already this "free market" is looking a little worse for wear. Now let's look at the other
          side of the equation: the doctors. In the past decade, hospitals and clinics across the
          country have consolidated into a handful of huge corporate behemoths. In the last 6 years
          alone, the number of doctors employed by one of these "health systems" has increased from
          25% (2012) to over 44% (Jan 2018). Doctors who work in these systems are kept entirely in
          the dark when it comes to money. They typically have no clue how much the hospital charges
          for each service; often, they don't even know what services the hospital is charging for!
          The process of a hospital requesting payment from your insurance company is dizzyingly
          complicated; that's why hospitals are consolidating in the first place—to manage the
          administrative burden of billing.
        </p>
        <p>
          So that's the state of the healthcare market: the two main players—doctors and
          patients—have no clue how much anything costs. Instead, they've both hired a third
          party—health insurance companies and huge corporate hospitals—to decide prices on their
          behalf.
        </p>
        <p>
          This bizarre, anti-competitive scenario is why healthcare prices have been rising steadily
          for years. In principle, the negotiations between hospitals and insurance companies should
          eventually shake out a fair price. But that's not what happens. You see: insurance
          companies are regulated. They're only allowed to make a fixed profit margin. So if they
          bring in $1M in premiums, they're required to pay out at least $800k. So the only way for
          them to increase their profits is to increase the amount of money that flows through them.
          That's right: the insurance company would prefer to pay for more procedures and at higher
          prices, so next year it can legally increase its premiums and, by extension, its profits.
          What could go wrong?
        </p>
        <img
          src="/img/premiums-over-time.jpg"
          className="mv32"
          style={{ borderRadius: '3px', width: '80%' }}
          alt="growth of administrative overhead"
        />
        <p>
          Answer: just about everything. The total amount spent on healthcare in America has
          increased by an average of 6.4% annually for the past two decades. That's faster than
          inflation AND average wages, so everyone in the country is spending a bigger and bigger
          chunk of their paycheck on healthcare.
        </p>
        <p>
          Fortunately there's a way to get of the runaway train that is American healthcare and
          enjoy fair prices for medical care! It's called direct primary care, and it's a totally
          new model for healthcare that throws useless middlemen out the window! Learn more about it{' '}
          <a href="https://dpcnation.org">here</a>, or find a practice near you on the{' '}
          <a
            onClick={gasend('find_a_practice')}
            target="_blank"
            rel="noopener noreferrer"
            href="https://mapper.dpcfrontier.com">
            DPC Mapper
          </a>
          .
        </p>
      </BlogPost>
    );
  }
}
