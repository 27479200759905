import React from 'react';
import { Button } from 'antd';
import { gasend } from '../util';

export default function CTASection() {
  const anchorStyle = { visibility: 'hidden', cursor: 'none' };
  return (
    <div className="col ac">
      <a id="cta" style={anchorStyle} />
      <img src="/img/highfive.svg" style={{ width: '200px' }} alt="Celebration illustration" />
      <h2 className="subtitle">Join a DPC practice</h2>
      <p>
        Join over a quarter million other Americans and become a member of a DPC practice! There are
        over 1,000 DPC practices spread across in 48 states, including multiple in every major city.
        To find a practice near you, go to the DPC Frontier mapper and search for your zip code. If
        you find a practice that looks good, visit their website directly for more information on
        how to schedule an initial visit.
      </p>
      <Button
        type="primary"
        size="large"
        target="_blank"
        href="https://mapper.dpcfrontier.com"
        onClick={gasend('find_a_practice')}>
        Find a Practice
      </Button>
      <br />
      <br />
      <br />
      <p>
        If you want to learn more about DPC, join the DPC Nation Facebook group! It's a fast-growing
        community of people who are tired of being the victims of a broken healthcare system and
        have taken their health into their own hands. It's a great way to learn more about direct
        primary care, staying healthy, saving money, and the future of healthcare in America.
      </p>
      <Button
        href="https://www.facebook.com/groups/dpcnation"
        target="_blank"
        rel="noopener noreferrer"
        icon="facebook"
        size="large"
        type="primary"
        onClick={() => {
          if (typeof window !== 'undefined') window.ga('send', 'join_fb_group');
          console.log('logged ga eveent');
        }}>
        Join the DPC Nation
      </Button>
    </div>
  );
}
